<template>
	<aside class="course__header">
		<v-container>
			<v-row class="align-center">
				<v-col cols="2">

					<span
						class="course__header--link"
						v-if="!isFirstPage"
						@click="prevPage"
					>
						<v-icon>mdi-chevron-left</v-icon>
						{{ prevButtonText }}
					</span>
					<span
						class="course__header--link"
						v-if="isFirstPage"
						@click="backcoursePage"
					>
						<v-icon>mdi-chevron-left</v-icon>
						Back to Course Page
					</span>
				</v-col>
				<v-col cols="8">
					<h2 class="course__header--name">
						{{ courseName }}
					</h2>
					<p v-if="courseDescription">{{ courseDescription }}</p>
				</v-col>
				<v-col cols="2">
					<span
						class="course__header--link"
						v-if="stage !== 0"
						@click="handleNextButton"
					>
						{{ nextButtonText }}
						<v-icon>mdi-chevron-right</v-icon>
					</span>
					<span
						v-else-if="stage === 2 && assignedQuiz !== undefined"
						class="course__header--link"
						@click="startQuiz"
					>
						{{ nextButtonText }}
						<v-icon>mdi-chevron-right</v-icon>
					</span>
				</v-col>
			</v-row>
		</v-container>
	</aside>
</template>

<script>
export default {
	name: "CourseHeader",
	props: {
		course: {
			type: Object,
			require: true
		},
		stage: {
			type: Number,
			require: true
		}
	},
	components: {},
	computed: {
		courseName() {
			return this.course?.title;
		},
		assignedQuiz() {
			return this.course.quizzes[0]?.quiz?.id;
		},
		courseDescription() {
			return this.course?.description;
		},
		nextButtonText() {
			if (this.stage === 0) {
				return "Start Course";
			} else if (this.stage === 2) {
				return "Start Quiz";
			} else {
				return "Next Chapter";
			}
		},
		prevButtonText() {
			return "Previous Chapter";
		},
		isFirstPage() {
			if (!!this.$route.query.chapter === false) return true;

			if (
				Number(this.$route.query.chapter) ===
					this.course?.chapters[0].id &&
				Number(this.$route.query.page) ===
					this.course?.chapters[0].pages[0].id
			) {
				return true;
			} else {
				return false;
			}
		},
		activePageIndex() {
			return this.course.chapters[
				this.activeChapterIndex
			].pages.findIndex(el => el.id === Number(this.$route.query.page));
		},
		activeChapterIndex() {
			return this.course.chapters.findIndex(
				el => el.id === Number(this.$route.query.chapter)
			);
		}
	},

	methods: {
		backcoursePage() {
			this.$router.push({ path: '/student/courses' })
		},
		handlePrevButton() {
			if (this.stage !== 0) {
				this.prevPage();
			}
		},
		handleNextButton() {

			if(this.course.chapters.length == this.stage) {
				this.startQuiz()
			} else if(this.stage === 0) {
				this.startCourse()
			} else {
				this.nextPage()
			}

		},
		startCourse() {
			this.$emit("setValue", { key: "stage", value: 1 });
			this.$router.push({
				query: {
					...this.$route.query,
					chapter: this.course.chapters[0].id,
					page: this.course.chapters[0].pages[0].id
				}
			});
			localStorage.setItem(
				`courseData[${this.$route.query.course}]`,
				JSON.stringify({
					chapterId: this.course.chapters[0].id,
					pageId: this.course.chapters[0].pages[0].id
				})
			);
		},
		startQuiz() {

			this.$router.push({
				name: "Quiz",
				query: {
					quiz: this.course.quizzes[0].quiz.id,
					studentCourse: this.$route.query.studentCourse
				}
			});

		},
		nextPage() {
			if (
				this.activePageIndex ===
					this.course.chapters[this.activeChapterIndex].pages.length -
						1 &&
				this.stage !== 2
			) {
				this.$router.push({
					query: {
						...this.$route.query,
						chapter:
							this.course?.chapters[this.activeChapterIndex + 1]
								.id,
						page: this.course?.chapters[this.activeChapterIndex + 1]
							.pages[0].id
					}
				});

				return;
			}
			this.$router.push({
				query: {
					...this.$route.query,
					page: this.course?.chapters[this.activeChapterIndex].pages[
						this.activePageIndex + 1
					].id
				}
			});
		},
		prevPage() {
			if (this.activePageIndex === 0 && this.stage !== 0) {
				this.$router.push({
					query: {
						chapter:
							this.course?.chapters[this.activeChapterIndex - 1]
								.id,
						page: this.course?.chapters[
							this.activeChapterIndex - 1
						].pages.at(-1).id
					}
				});
				return;
			}
			this.$router.push({
				query: {
					...this.$route.query,
					page: this.course?.chapters[this.activeChapterIndex].pages[
						this.activePageIndex - 1
					].id
				}
			});
		}
	},

	mounted() {

	}
};
</script>

<style></style>
